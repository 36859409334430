import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
//  引入Echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "@/assets/css/index.scss"; // reset el
import './permission';

import comDialog from '@/components/comDialog.vue'
Vue.use(ElementUI)
Vue.component('comDialog',comDialog)
import axios from 'axios'
Vue.prototype.axios = axios

Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
