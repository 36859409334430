<template>
	<div id="app">
		<div v-if="isLogin">
			<router-view />
		</div>
		<div v-else>
			<div class="top">
				<top-bar></top-bar>
			</div>
			<div class="MAPS">
				<gaode-map></gaode-map>
			</div>
			<div class="content">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>
	import gaodeMap from '@/components/gaodeMap/index.vue';
	import topBar from '@/components/topBar/index.vue';
	export default {
		components: {
			gaodeMap,
			topBar,
		},
		computed: {
			isLogin() {
				const route = this.$route;
				if (route.path == '/login-index') {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>
<style lang="scss">
	.MAPS {
		width: 100%;
		// height: 60vw;
		height: 100vh;
		box-sizing: border-box;
		overflow: hidden;
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		// height: 60vw;
		height: 100vh;
		z-index: 888;
		overflow: hidden;
		pointer-events: none;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		position: relative;

		.top {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 999;
		}
	}

	* {
		padding: 0;
		margin: 0;
	}

	html::-webkit-scrollbar {
		// width: 12px;
		display: none;
	}
	// html::-webkit-scrollbar-thumb {
	//   border-radius: 5px;
	//   background: rgba(4, 243, 247, 0.3);
	// }
	// html::-webkit-scrollbar-track {
	//   border-radius: 0;
	//   background: rgba(0, 0, 0, 0.1);
	// }
</style>
