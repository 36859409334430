<template>
	<div>
		<div class="bg">
			<img src="@/static/top-img.png" alt="" class="top-img" />
			<div class="login-box">
				<div class="text">欢迎登录</div>
				<div class="input-dv">
					<img src="@/static/username.png" alt="" />
					<el-input placeholder="请输入账号" v-model="username"></el-input>
				</div>
				<div class="input-dv">
					<img src="@/static/password.png" alt="" />
					<el-input placeholder="请输入密码" type="password" v-model="password"></el-input>
				</div>
				<div class="flex_center">
					<div class="btn" @click="login1">登录</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Loading } from 'element-ui';
	export default {
		name: 'loginC',
		data() {
			return {
				username: '',
				password: '',
				loading: process.env.NODE_ENV != 'development', // 本地false  线上true
				loadingInstance: null,
			};
		},
		created() {
			// console.log(process.env.NODE_ENV);
			// if (this.loading) {
			// 	this.login();
			// 	this.loadingInstance = Loading.service({
			// 		text: '加载中...',
			// 		background: 'rgba(0, 0, 0, 0.5)',
			// 	});
			// }

			// 点单登录
		
		},
		mounted() {},
		methods: {
			login1() {
				this.axios({
					method: 'post',
					url: 'http://meishan.mlscd.cn/prod-api/login',
					data: {
						username: this.username,
						password: this.password,
					},
				}).then(res => {
					console.log(res, '85888');
					if (res.data.code != 200) {
						this.$message.error(res?.msg || '登录失败');
						return;
					}
					sessionStorage.setItem('token_ksh', res.token);
					this.$router.push('/');
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	.flex_center {
		display: flex;
		justify-content: center;
	}
	.bg {
		width: 100vw;
		height: 100vh;
		background-image: url('../static/bg2x.png');
		background-size: 100% 100%;
		position: relative;

		.top-img {
			width: 100%;
			height: 110px;
			position: absolute;
			top: 0;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		.login-box {
			background-image: url('../static/foot-box.png');
			background-size: 100% 100%;
			width: 600px;
			// height: 492px;
			position: absolute;
			right: 169px;
			top: 199px;
			padding: 89px 146px 93px 146px;
			text-align: center;
			box-sizing: border-box;
		}

		.text {
			font-size: 24px;
			color: #01ecfd;
			font-weight: bold;
		}

		.input-dv {
			display: flex;
			align-items: center;
			width: 306px;
			height: 61px;
			background-image: url('../static/input.png');
			background-size: 100% 100%;
			padding: 19px 21px;
			margin-top: 35px;

			img {
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}

			::v-deep {
				.el-input__inner {
					background: none;
					border: none;
					color: #fff;
				}
			}
		}

		.btn {
			width: 106px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-image: url('../static/btn-bg.png');
			background-size: 100% 100%;
			margin-top: 47px;
			color: #08ffae;
			font-size: 18px;
			cursor: pointer;
		}
	}
</style>
