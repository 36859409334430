/*
 * @Author: wwxin
 * @Date: 2023-11-17 15:06:03
 * @LastEditors: wwxin
 * @LastEditTime: 2023-11-21 09:59:49
 * @FilePath: /宜宾应急3/src/permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import router from './router';
router.beforeEach((to, from, next) => {
	// 1.如果访问的是登录页面（无需权限），直接放行
	if (to.path === '/login-index') return next();
	// 2.如果访问的是有登录权限的页面，先要获取token
	const tokenStr = sessionStorage.getItem('token_ksh');
	// 2.1如果token为空，强制跳转到登录页面；否则，直接放行
	if (!tokenStr) {
		return next('/login-index');
	}
	next();
});
