<template>
	<div class="topbar">
		<el-row type="flex" justify="space-between" :gutter="10">
			<el-col :span="8">
				<div class="select_bar">
					<div
						v-for="(item, index) in routerList"
						:key="index"
						class="bar"
						@click="RouterTo(item, index)"
						:style="current == index ? 'background-image: url(' + activeBar + ')' : ''">
						{{ item.name }}
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="toptitle">
					<img src="@/assets/topbar/title.png" class="imgs" />
				</div>
			</el-col>
			<el-col :span="8">
				<div class="weatherday">
					<div class="wearthImg">
						<!-- <i :class="weather.wea_img"></i> -->
						<img :src="weather.wea_img" alt="" />
					</div>
					<div class="we1">
						<div>{{ weather.tem_day }}℃ ~ {{ weather.tem_night }}℃</div>
						<div>{{ weather.week }} {{ weather.wea }}</div>
					</div>
					<div class="line"></div>
					<div class="dateTime">
						<div>
							{{ weather.date }}
						</div>
						<div>
							{{ time }}
						</div>
					</div>

					<div
						style="font-weight: bold; font-size: 20px; margin-left: 2rem; cursor: pointer; color: #fff"
						@click="logOut">
						退出登录
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { gethours } from '@/utils/comonfn';
	import activeBar from '@/assets/topbar/active_bar.png';
	export default {
		name: 'topBar',
		data() {
			return {
				current: 0,
				routerList: [
					{
						path: '/',
						name: '首页',
					},
					{
						path: '/tourism',
						name: '智慧旅游',
					},
					{
						path: '/ruralVitalization',
						name: '乡村振兴',
					},
					{
						path: '/townScreen',
						name: '一镇一屏',
					},
					{
						path: '/RenrenTong',
						name: '人人通',
					},
				],
				activeBar: activeBar,
				weatherIMg: [
					{
						name: 'yu',
						icon: require('@/assets/tq (6).png'),
					},
					{
						name: 'lei',
						icon: require('@/assets/tq (7).png'),
					},
					{
						name: 'wu',
						icon: require('@/assets/tq (9).png'),
					},
					{
						name: 'shachen',
						icon: require('@/assets/tq (8).png'),
					},
					{
						name: 'qing',
						icon: require('@/assets/tq (4).png'),
					},
					{
						name: 'yin',
						icon: require('@/assets/tq (1).png'),
					},
					{
						name: 'bingbao',
						icon: require('@/assets/tq (10).png'),
					},
					{
						name: 'yun',
						icon: require('@/assets/tq (5).png'),
					},
					{
						name: 'xue',
						icon: require('@/assets/tq (2).png'),
					},
				],
				weather: {
					tem: '1',
					tem_night: '3',
					week: '周六',
					wea: '晴',
					date: '2022-12-03',
					wea_img: require('@/assets/tq (6).png'),
				},
				time: '',
				timer: 0,
				activeIndex: 0,
			};
		},
		mounted() {
			this.getWeaAndTem();
			this.timer = setInterval(this.getTime, 1000);
		},
		watch: {
			$route: {
				handler: function (val, oldVal) {
					this.routerList.forEach((fd, index) => {
						console.log(fd.path, val.path);
						if (fd.path == val.path) {
							this.current = index;
						}
					});
				},
				// 深度观察监听
				deep: true,
				immediate: true,
			},
		},
		methods: {
			logOut() {
				sessionStorage.clear();
				this.$router.push({
					path:'/login-index',
				});
			},
			RouterTo(item, index) {
				this.activeIndex = index;
				this.$router.replace({
					path: item.path,
				});
			},
			getTime() {
				this.time = gethours();
			},
			getWeaAndTem() {
				this.axios({
					methods: 'get',
					url: 'http://v1.yiketianqi.com/free/day',
					headers: {},
					params: {
						appid: '85312899', // 账号ID
						appsecret: 'DBgl8uhG', // 账号密钥
						city: '简阳', // 城市名称,不要带市和区
						ip: null, // IP地址
						callback: 0, // jsonp方式
					},
				})
					.then(res => {
						this.weather = res.data;
						this.weatherIMg.map(item => {
							if (item.name == this.weather.wea_img) {
								this.weather.wea_img = item.icon;
							}
						});
					})
					.catch(error => {
						console.log(error);
					});
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/css/mixins.scss';

	.topbar {
		@include pt(30);
	}

	.select_bar {
		display: flex;
		align-items: center;
		justify-content: space-around;
		cursor: pointer;
		@include pl(22);

		.bar {
			@include wd(156);
			@include height(48);
			@include lh(48);
			@include font(18);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			color: #fff;
		}
	}

	.toptitle {
		width: 100%;
		text-align: center;

		.imgs {
			width: auto;
			@include height(90);
			margin: 0 auto;
		}
	}

	.weatherday {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		@include font(16);

		.we1 {
			width: 20%;
			@include lh(20);
		}

		.line {
			@include wd(2);
			@include height(25);
			background-color: #4ee4ff;
		}

		.dateTime {
			margin-left: 5%;
			@include lh(20);
		}

		.wearthImg {
			img {
				@include wd(34);
				@include height(34);
			}
		}
	}
</style>
